import React from "react"
import WellzestaLogo from "common/icons/WellzestaLogo"
import styles from "./LogIn.module.css"

const LoginPageTitle = () => {
    return (
        <div className={styles.leftCol}>
            <div className={styles.logo}>
                <WellzestaLogo />
                <span>Wellzesta TV+</span>
            </div>
            <span>Promoting health and vitality.</span>
        </div>
    )
}

export default LoginPageTitle
