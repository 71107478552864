import axios from "axios"
import { setCurrentOrganization, setToken } from "lib/auth.js"
// Queries
import { COMMUNITY_LOGIN_QUERY } from "lib/queries/communityLogin"
import { getCommunityConfiguration } from "lib/indexedb"

export const loginAsCommunity = async (token, autoLogin = false) => {
    try {
        const response = await axios.post(
            process.env.NEXT_PUBLIC_GRAPHQL_API_URL,
            {
                query: COMMUNITY_LOGIN_QUERY
            },
            {
                headers: {
                    Authorization: token || "",
                    "Content-Type": "application/json"
                }
            }
        )
        const { data } = response.data

        const { currentUser } = data

        if (!currentUser) {
            throw new Error("Invalid Community Token")
        }
        setToken(token, autoLogin)
        setCurrentOrganization({
            ...currentUser?.organization,
            community: true,
            autoLoggedIn: autoLogin
        })
        const configuration = await getCommunityConfiguration()
        return { success: true, settingsConfigured: configuration, autoLogin }
    } catch (error) {
        console.error(error)
        throw error
    }
}
