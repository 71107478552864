import React from "react"
import { Box } from "reakit"

const Link = React.forwardRef(
    ({ theme, size, children, className, hidden, disabled, ...props }, ref) => {
        return (
            <Box
                as="a"
                ref={ref}
                hidden={hidden}
                disabled={disabled}
                {...props}
            >
                {children}
            </Box>
        )
    }
)

export default Link
