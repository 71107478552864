import React from "react"
import { Text, Input } from "../../wellzesta-ui"
import { useField } from "formik"
import styled from "styled-components"

const StyledFormField = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    input {
        color: #0f172a;
        background: #cbd5e1;
        padding: 1rem;
        font-family: "Inter", sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
        border: none;
        border-radius: 4px;

        ::placeholder {
            color: #0f172a;
        }

        &:focus {
            outline: 4px solid #4d88f8;
            outline-offset: -4px;
        }

        ${({ error }) =>
            error &&
            `
                border: 1px solid red;
            `}
    }
`

const ErrorMessage = styled(Text)`
    display: flex;
    color: #f97773;
    margin-top: 0.25rem;
    line-height: 1;
    font-size: 1.7rem;
    margin-bottom: 1rem;
`

const FieldLabel = styled(Text)`
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 1rem;
`

const FormField = React.memo(function FormField({
    label,
    name,
    validate,
    className,
    component: Component = Input,
    ...props
}) {
    const [field, meta] = useField({ name, ...props })
    const hasError = meta.dirty && meta.error

    return (
        <StyledFormField error={hasError}>
            {label && (
                <FieldLabel
                    size="sm"
                    htmlFor={name}
                    as="label"
                    className="mb-2 text-left"
                >
                    {label}
                </FieldLabel>
            )}
            <Component id={name} {...field} {...props} />
            <ErrorMessage size="sm" hidden={!hasError} as="span">
                {hasError}
            </ErrorMessage>
        </StyledFormField>
    )
})

export default FormField
