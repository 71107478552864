import React from "react"
import Background from "common/LogIn/Background"
import LogIn from "common/LogIn"

export default function IndexPage() {
    return (
        <Background>
            <LogIn />
        </Background>
    )
}
