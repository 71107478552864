import React from "react"
import { Button as ReakitButton, styled } from "reakit"

const StyledButton = styled(ReakitButton)`
    display: flex;
    justify-content: center;
    padding-left: 4rem;
    padding-right: 4rem;
    font-size: 1.7rem;
    font-weight: 800;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 1rem;
    width: ${(props) => (props.width ? props.width : "100%")};
    border-radius: 4px;
    color: #fff;
    background-color: #0f766e;
    margin-left: auto;
    margin-right: auto;

    &:focus {
        outline: 4px solid #4d88f8;
        outline-offset: -4px;
    }

    ${({ secondary }) =>
        secondary &&
        `
            color: #0f766e;
            background-color: #f0fdfa;
        `}
`

const Button = React.forwardRef(
    (
        {
            children,
            size,
            theme,
            className,
            active,
            disabled,
            type = "button",
            ...props
        },
        ref
    ) => {
        return (
            <StyledButton
                ref={ref}
                type={type}
                disabled={disabled}
                {...props}
                className={className}
            >
                {children}
            </StyledButton>
        )
    }
)
export default Button
