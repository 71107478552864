import React from "react"
import { styled } from "reakit"

const StyledBackground = styled.div`
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-image: linear-gradient(
            46.04deg,
            rgb(0, 0, 0) 28.37%,
            rgba(0, 0, 0, 0) 73.66%
        ),
        url("/static/images/background.png");
    background-color: black;

    .content {
        position: absolute;
        width: 100%;
        height: 100vh;
    }
`

const Background = ({ children }) => {
    return (
        <StyledBackground>
            {/* <img src="/static/images/background.png"></img> */}
            <div className="content">{children}</div>
        </StyledBackground>
    )
}

export default Background
