import React from "react"
import { Button } from "wellzesta-ui"
import { Formik, Form } from "formik"
import FormField from "../form/FormField"
import styles from "./LogIn.module.css"

import { loginAsCommunity } from "lib/auth/authService"
import yup from "../../lib/yup.validations"
import createApolloClient from "lib/apolloClient"
import { cookies, getCurrentOrganizationId } from "lib/auth"
import { useRouter } from "next/router"
import { useApolloClient } from "lib/ApolloClient/ApolloClientProvider"

const CommunityLoginTokenFormSchema = yup.object().shape({
    token: yup.string().required()
})

const LoginPageForm = ({ title }) => {
    const router = useRouter()
    const { setApolloClient } = useApolloClient()

    const onSubmit = async ({ token }, actions) => {
        try {
            const loginResult = await loginAsCommunity(token)

            if (loginResult.success) {
                const { slug } = getCurrentOrganizationId()
                if (loginResult?.settingsConfigured) {
                    setApolloClient(createApolloClient(cookies.get("TOKEN")))
                    router.push(`/${slug}/content`)
                } else {
                    router.push(`/${slug}/settings`)
                }
            }
        } catch (error) {
            actions.setStatus({ apiError: "Invalid token. Try again." })
        }
    }

    return (
        <div className="right-col">
            <div className={styles.card}>
                <div className={styles.title}>{title}</div>
                <Formik
                    onSubmit={onSubmit}
                    validationSchema={CommunityLoginTokenFormSchema}
                    initialValues={{ token: "" }}
                >
                    {({ isSubmitting, status }) => (
                        <Form className={styles.form}>
                            {status?.apiError && (
                                <div className={styles.alert} role="alert">
                                    {status.apiError}
                                </div>
                            )}
                            <FormField
                                label="Token"
                                name="token"
                                placeholder="Type the community token"
                                type="password"
                                tabIndex="0"
                            />
                            <Button
                                type="submit"
                                theme="secondary"
                                size="md"
                                disabled={isSubmitting}
                                tabIndex="0"
                            >
                                Sign-In
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default LoginPageForm
