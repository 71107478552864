import React from "react"

const WellzestaLogo = () => (
    <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9068 59.0096C17.8745 54.2448 12.6287 50.3484 7.2506 50.3484C1.86916 50.3484 -1.19539 56.5199 0.440007 64.0639C2.07428 71.6069 16.1315 84.159 19.9347 80.4233C23.7402 76.6887 19.9381 63.7745 18.9068 59.0096Z"
            fill="#6BBF71"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.3687 78.4543C29.394 75.8017 23.0307 76.6877 19.2263 80.4234C15.4231 84.1591 17.4598 90.6498 23.7525 94.85C30.0453 99.0502 49.855 98.1631 49.855 92.8809C49.855 87.5988 37.3423 81.1059 33.3687 78.4543Z"
            fill="#0CB14B"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.1857 67.2326C76.4866 71.1345 77.3878 77.3842 81.191 81.1188C84.9954 84.8556 91.6064 82.8545 95.8827 76.6753C100.16 70.4961 99.2577 51.0438 93.8774 51.0438C88.4982 51.0438 81.886 63.3307 79.1857 67.2326Z"
            fill="#3988C8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.9485 0.431817C58.2658 -1.17297 51.981 1.83628 51.981 7.11954C51.981 12.4006 55.9501 17.5518 60.8036 18.5655C65.6548 19.5781 78.8052 23.3116 82.6073 19.577C86.4117 15.8413 73.6312 2.03661 65.9485 0.431817Z"
            fill="#A46AAC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M97.2997 25.4115C93.0223 19.2323 86.4112 17.2324 82.608 20.9681C78.8048 24.7038 77.9025 30.9523 80.6016 34.8542C83.3041 38.7572 89.9152 51.0441 95.2955 51.0441C100.675 51.0441 101.576 31.5907 97.2997 25.4115Z"
            fill="#0071BB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.6778 81.4338C53.8243 82.4465 49.8552 87.5987 49.8552 92.8809C49.8552 98.1631 56.1401 101.173 63.8228 99.5686C71.5043 97.9638 84.2859 84.1591 80.4827 80.4234C76.6795 76.6877 63.5291 80.4201 58.6778 81.4338Z"
            fill="#44C7F3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.1584 18.5656C48.0107 17.5519 51.981 12.4007 51.981 7.11966C51.981 1.83641 45.695 -1.17285 38.0123 0.431939C30.3308 2.03563 17.548 15.8414 21.3524 19.5771C25.1567 23.3118 38.3071 19.5782 43.1584 18.5656Z"
            fill="#F6931D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9411 33.4635C24.6413 29.5605 23.7401 23.3119 19.9347 19.5762C16.1314 15.8416 9.52036 17.8405 5.24413 24.0197C0.966769 30.1989 1.86909 49.6523 7.25054 49.6523C12.6286 49.6523 19.2408 37.3654 21.9411 33.4635Z"
            fill="#EC1D24"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.1702 51.9811C42.9438 50.9343 41.7882 50.0769 40.6067 50.0769C39.4231 50.0769 38.7505 51.434 39.1092 53.0939C39.469 54.7515 42.5605 57.5109 43.3966 56.6898C44.2328 55.8676 43.3978 53.0289 43.1702 51.9811Z"
            fill="#6BBF71"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.5065 56.2569C45.6321 55.6747 44.2333 55.8673 43.3971 56.6906C42.5609 57.5117 43.0081 58.9404 44.3913 59.8628C45.7767 60.7862 50.1314 60.5914 50.1314 59.4291C50.1314 58.2679 47.3796 56.8403 46.5065 56.2569Z"
            fill="#0CB14B"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5805 53.7891C55.9875 54.6476 56.1859 56.0213 57.0232 56.8424C57.8583 57.6646 59.3132 57.2254 60.2537 55.8672C61.193 54.5067 60.9946 50.2295 59.812 50.2295C58.6284 50.2295 57.1746 52.9317 56.5805 53.7891Z"
            fill="#3988C8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.0706 56.9126C51.0046 57.1361 50.1326 58.2675 50.1326 59.4288C50.1326 60.5911 51.5135 61.2526 53.2038 60.8993C54.893 60.5471 57.7031 57.5114 56.8658 56.6903C56.0296 55.8681 53.1377 56.6903 52.0706 56.9126Z"
            fill="#44C7F3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.9819 39.0997C52.2927 38.7475 50.9106 39.409 50.9106 40.5702C50.9106 41.7314 51.7838 42.8651 52.8498 43.0874C53.9169 43.3109 56.8088 44.1309 57.6461 43.3109C58.4823 42.4887 55.6711 39.4519 53.9819 39.0997Z"
            fill="#A46AAC"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.7204 44.5922C59.78 43.234 58.3261 42.7948 57.49 43.6159C56.6538 44.437 56.4542 45.8107 57.0483 46.6703C57.6435 47.5266 59.0973 50.2288 60.2788 50.2288C61.4624 50.2288 61.6608 45.9515 60.7204 44.5922Z"
            fill="#0071BB"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.3478 43.0874C49.4149 42.8651 50.2869 41.7314 50.2869 40.5702C50.2869 39.409 48.9049 38.7475 47.2157 39.0997C45.5276 39.4519 42.7164 42.4887 43.5526 43.3109C44.3887 44.1309 47.2807 43.3109 48.3478 43.0874Z"
            fill="#F6931D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.6814 46.3636C44.2743 45.5062 44.0771 44.1314 43.2409 43.3114C42.4047 42.4892 40.9509 42.9284 40.0104 44.2866C39.07 45.6459 39.2673 49.9232 40.4509 49.9232C41.6335 49.9232 43.0884 47.221 43.6814 46.3636Z"
            fill="#EC1D24"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.2194 58.0133C63.8418 60.0969 64.3025 63.3582 66.2406 65.2646C68.1797 67.1688 71.5514 66.0274 73.7327 62.7286C75.9128 59.431 75.4533 49.9277 72.7104 49.9277C69.9664 49.9277 66.597 55.9297 65.2194 58.0133Z"
            fill="#D8C4DF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.8874 45.6307C67.3459 47.995 69.9665 49.9278 72.7094 49.9278C75.4534 49.9278 77.1022 46.8668 76.3759 43.1245C75.6484 39.3821 68.1798 32.6889 66.2407 34.5931C64.3015 36.4984 66.429 43.2686 66.8874 45.6307Z"
            fill="#7DA7D8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.2483 33.456C48.6538 33.0047 50.6232 30.4324 50.6232 27.739C50.6232 25.0457 47.506 23.4255 43.6949 24.1398C39.8839 24.8531 33.0654 32.1869 35.0068 34.0911C36.946 35.9963 43.8429 33.9073 46.2483 33.456Z"
            fill="#F59679"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.5657 35.5972C61.6875 36.9489 65.0088 36.4976 66.9502 34.5934C68.8893 32.6881 67.727 29.3784 64.3687 27.2365C61.0094 25.0945 51.3327 25.548 51.3315 28.2403C51.3315 30.9347 57.4438 34.2445 59.5657 35.5972Z"
            fill="#44C7F3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.6907 62.4214C28.8719 65.7212 32.2425 66.8604 34.1828 64.9562C36.1208 63.0521 36.5815 59.7886 35.205 57.7061C33.8263 55.6214 30.4558 49.6205 27.7141 49.6205C24.9712 49.6216 24.5105 59.1237 26.6907 62.4214Z"
            fill="#7DA7D8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.105 41.8706C23.3776 45.6118 25.0264 48.6739 27.7704 48.6739C30.5143 48.6739 33.1339 46.74 33.5923 44.3769C34.0519 42.0148 36.1794 35.2434 34.2402 33.3392C32.2999 31.434 24.8314 38.1283 24.105 41.8706Z"
            fill="#7DA7D8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.8797 64.2595C39.7568 62.9078 36.4344 63.3591 34.4952 65.2644C32.5572 67.1686 33.7185 70.4783 37.0756 72.6202C40.4349 74.7611 50.1128 74.3098 50.1128 71.6164C50.1128 68.922 44.0016 65.6122 41.8797 64.2595Z"
            fill="#F6931D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.9989 65.7051C52.5934 66.1553 50.624 68.7276 50.624 71.422C50.624 74.1165 53.7424 75.7356 57.5523 75.0224C61.3634 74.308 68.1807 66.9742 66.2404 65.07C64.3024 63.1647 57.4066 65.2549 54.9989 65.7051Z"
            fill="#F59679"
        />
    </svg>
)

export default WellzestaLogo
